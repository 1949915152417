import { FC } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import IconProps from './IconProps';

const InfoIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7902 16.727C13.3425 16.727 13.7902 16.2793 13.7902 15.727C13.7902 15.1747 13.3425 14.727 12.7902 14.727V16.727ZM11.8112 15.727H10.8112C10.8112 16.2793 11.2589 16.727 11.8112 16.727V15.727ZM11.8112 11.811H12.8112C12.8112 11.2587 12.3635 10.811 11.8112 10.811V11.811ZM10.8322 10.811C10.2799 10.811 9.83218 11.2587 9.83218 11.811C9.83218 12.3633 10.2799 12.811 10.8322 12.811V10.811ZM12.7902 14.727H11.8112V16.727H12.7902V14.727ZM12.8112 15.727V11.811H10.8112V15.727H12.8112ZM11.8112 10.811H10.8322V12.811H11.8112V10.811ZM19.622 11.811C19.622 16.125 16.125 19.622 11.811 19.622V21.622C17.2295 21.622 21.622 17.2295 21.622 11.811H19.622ZM11.811 19.622C7.49711 19.622 4 16.125 4 11.811H2C2 17.2295 6.39255 21.622 11.811 21.622V19.622ZM4 11.811C4 7.49711 7.49711 4 11.811 4V2C6.39254 2 2 6.39254 2 11.811H4ZM11.811 4C16.125 4 19.622 7.49711 19.622 11.811H21.622C21.622 6.39255 17.2295 2 11.811 2V4Z"
        fill="currentColor"
      />
      <path d="M12 8V8.35526" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default InfoIcon;
