export enum PeriodicReviewStatus {
  UpcomingReview = 0,
  UnderReview = 1,
  ReviewOverdue = 2,
  ReviewCompleted = 3,
  ReviewArchived = 4,
}

export const PeriodicReviewStatusKeys = {
  [PeriodicReviewStatus.UpcomingReview]: 'common:periodic-review-status.upcoming',
  [PeriodicReviewStatus.UnderReview]: 'common:periodic-review-status.under-review',
  [PeriodicReviewStatus.ReviewOverdue]: 'common:periodic-review-status.overdue',
  [PeriodicReviewStatus.ReviewCompleted]: 'common:periodic-review-status.completed',
  [PeriodicReviewStatus.ReviewArchived]: 'common:list.filter.archived',
} as const;
