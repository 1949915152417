import { useRef, useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export const useNavigateRef = () => {
  const navigate = useNavigate();
  const navRef = useRef<NavigateFunction>(navigate);

  useEffect(() => {
    navRef.current = navigate;
  }, [navigate]);

  return navRef;
};
