import { lazy } from 'react';
import { Roles } from '../models/Role';
import RouteWithTitle from '../models/RouteWithTitle';

const adminRoutes: RouteWithTitle[] = [
  {
    id: 'admin-landing',
    path: '/builder/:tenantId',
    translationKey: 'page.admin-landing',
    requiresArchitect: true,
    component: lazy(() => import('../pages/admin/AdminLanding')),
  },
  {
    id: 'form-builder',
    path: ['/builder/:tenantId/form-builder/:templateFormId/:templateFormVersion', '/builder/:tenantId/form-builder'],
    translationKey: 'page.form-builder',
    requiresArchitect: true,
    component: lazy(() => import('../pages/admin/FormBuilder')),
  },
  {
    id: 'resource-builder',
    path: ['/builder/:tenantId/resource-builder/:templateFormId/:templateFormVersion', '/builder/:tenantId/resource-builder'],
    translationKey: 'page.resource-builder',
    requiresArchitect: true,
    component: lazy(() => import('../pages/admin/ResourceBuilder')),
  },
  {
    id: 'form-template-list',
    path: '/builder/:tenantId/forms',
    translationKey: 'page.form-template-list',
    requiresArchitect: true,
    component: lazy(() => import('../pages/admin/FormTemplates')),
  },
  {
    id: 'module-template-list',
    path: '/builder/:tenantId/modules',
    translationKey: 'page.module-template-list',
    requiresArchitect: true,
    component: lazy(() => import('../pages/admin/ModuleTemplateList')),
  },
  {
    id: 'module-builder',
    path: ['/builder/:tenantId/module-builder/:templateModuleId/sections', '/builder/:tenantId/module-builder/:templateModuleId/forms/:sectionId'],
    translationKey: 'page.module-builder',
    requiresArchitect: true,
    component: lazy(() => import('../pages/admin/ModuleBuilder')),
  },
  {
    id: 'picklist-builder',
    path: '/builder/:tenantId/picklists',
    translationKey: 'page.picklist-builder',
    requiresArchitect: true,
    component: lazy(() => import('../pages/admin/picklist-builder/PicklistBuilder')),
  },
  {
    id: 'picklist-details',
    path: '/builder/:tenantId/picklists/:picklistId',
    translationKey: 'page.picklist-details',
    requiresArchitect: true,
    component: lazy(() => import('../pages/admin/picklist-builder/PicklistDetails')),
  },
  {
    id: 'resource-template-list',
    path: '/builder/resources',
    translationKey: 'page.resource-template-list',
    requiresArchitect: true,
    component: lazy(() => import('../pages/admin/ResourceTemplates')),
  },

  {
    id: 'iam-organisations',
    path: '/admin/iam/organisations',
    translationKey: 'page.iam-organisations',
    permissions: [Roles.SuperAdmin],
    component: lazy(() => import('../pages/admin/iam/Organisations')),
  },
  {
    id: 'iam-users',
    path: ['/admin/iam/users', '/admin/iam/organisations/:accountId'],
    translationKey: 'page.iam-users',
    permissions: [Roles.SuperAdmin],
    component: lazy(() => import('../pages/admin/iam/Users')),
  },
  {
    id: 'iam-user-detail',
    path: '/admin/iam/users/:userId',
    translationKey: 'page.iam-user-detail',
    permissions: [Roles.SuperAdmin],
    component: lazy(() => import('../pages/admin/iam/UserDetail')),
  },
] as RouteWithTitle[];

export default adminRoutes;
