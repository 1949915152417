import { FC, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import RouteWithTitle from './models/RouteWithTitle';

const PublicRouteWithTitle: FC<RouteWithTitle> = ({ translationKey, ...props }) => {
  const { t } = useTranslation('navigation');
  const title = translationKey ? t(translationKey) : '';

  document.title = `${title ? title + ' - ' : ''}Playbook`;

  return createElement(props.component);
};

export default PublicRouteWithTitle;
