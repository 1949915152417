import { lazy, Suspense, useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import PageLoader from '../components/shared/page-loader/PageLoader';
import { currentUserAtom } from '../recoil/atoms/Auth';
import { currentLayoutAtom, currentRouteAtom } from '../recoil/atoms/Layout';
import { FCWithChildren } from '../types/FCWithChildren';
const AuthLayout = lazy(() => import('./AuthLayout'));
const LoggedInLayout = lazy(() => import('./LoggedInLayout'));
const MinimalLayout = lazy(() => import('./MinimalLayout'));
const PublicLayout = lazy(() => import('./PublicLayout'));

export enum Layouts {
  LoggedIn,
  Minimal,
  Public,
  Auth,
}

type LayoutProps = {
  layout: keyof typeof Layouts;
};

export const Layout: FCWithChildren<LayoutProps> = (props) => {
  const { layout, children } = props;
  const layoutValue = Layouts[layout];
  const setCurrentLayout = useSetRecoilState(currentLayoutAtom);

  useEffect(() => {
    setCurrentLayout(layout);
    return () => setCurrentLayout(null);
  }, [layout, setCurrentLayout]);

  const component = useMemo(() => {
    switch (layoutValue) {
      case Layouts.LoggedIn:
        return <LoggedInLayout key="loggedInLayout">{children}</LoggedInLayout>;
      case Layouts.Public:
        return <PublicLayout>{children}</PublicLayout>;
      case Layouts.Auth:
        return <AuthLayout>{children}</AuthLayout>;
      case Layouts.Minimal:
        return <MinimalLayout>{children}</MinimalLayout>;
      default:
        throw new Error('Unknown layout ' + layout);
    }
  }, [children, layout, layoutValue]);

  return <Suspense fallback={<PageLoader loading isSuspense />}>{component}</Suspense>;
};

export const AutoLayout: FCWithChildren = (props) => {
  const { children } = props;
  const route = useRecoilValue(currentRouteAtom);
  const currentUser = useRecoilValue(currentUserAtom);

  if (route?.layout) {
    return <Layout layout={route.layout}>{children}</Layout>;
  }

  if (route?.publicPage) {
    return <Layout layout="Public">{children}</Layout>;
  }

  return <Layout layout={currentUser ? 'LoggedIn' : 'Public'}>{children}</Layout>;
};
