export enum ProcessesCallbacksNames {
  Success = 'Success',
  Failure = 'Failure',
}

export type ProcessesCallbacks = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [ProcessesCallbacksNames.Success]: (id: string, data: any) => void;
  [ProcessesCallbacksNames.Failure]: (id: string) => void;
};

export enum ProcessesMethodNames {
  SubscribeToProcess = 'SubscribeToProcess',
  UnsubscribeFromProcess = 'UnsubscribeFromProcess',
}

export type ProcessesMethods = {
  [ProcessesMethodNames.SubscribeToProcess]: (id: string) => void;
  [ProcessesMethodNames.UnsubscribeFromProcess]: (id: string) => void;
};

export interface ProcessesHub {
  methodsName: ProcessesMethodNames;
  methods: ProcessesMethods;

  callbacksName: ProcessesCallbacksNames;
  callbacks: ProcessesCallbacks;
}
