import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateUtils from '../../utils/DateUtils';
import Tooltip from './Tooltip';

type TimeAgoProps = {
  date: Date | null;
  mode?: 'ago' | 'till';
  disableTime?: boolean;
};

const TimeAgo: FC<TimeAgoProps> = (props) => {
  const { date, mode = 'ago', disableTime } = props;
  const { t } = useTranslation('common');
  const updateTimer = useRef<number>(0);
  const [text, setText] = useState('-');

  const updateText = useCallback(
    (date: Date | null) => {
      if (!date) {
        setText('-');
        return;
      }

      const diffrence = mode === 'ago' ? new Date().getTime() - date.getTime() : date.getTime() - new Date().getTime();
      const seconds = Math.round(diffrence / 1000);
      const days = Math.round(seconds / (24 * 60 * 60));
      const hours = Math.round(seconds / (60 * 60));
      const minutes = Math.round(seconds / 60);

      let time = disableTime ? DateUtils.formatDate(date) : DateUtils.formatDateTime(date);
      if (days < 7) {
        time = t(mode === 'ago' ? 'time-ago.days' : 'time-till.days', { days: days });
      }
      if (hours < 24) {
        time = t(mode === 'ago' ? 'time-ago.hours' : 'time-till.hours', { hours: hours });
      }
      if (minutes < 60) {
        time = t(mode === 'ago' ? 'time-ago.minutes' : 'time-till.minutes', { minutes: minutes });
      }
      if (seconds < 60) {
        time = t(mode === 'ago' ? 'time-ago.seconds' : 'time-till.seconds', { seconds: seconds });
      }
      if (seconds < 5) {
        time = t(mode === 'ago' ? 'time-ago.a-few-seconds' : 'time-till.a-few-seconds');
      }

      setText(time);

      // update time after a bit
      if (seconds < 60) {
        updateTimer.current = window.setTimeout(() => updateText(date), 5 * 1000 /* 5 sec */);
      } else if (hours < 1) {
        updateTimer.current = window.setTimeout(() => updateText(date), 5 * 60 * 1000 /* 5 min */);
      }
    },
    [disableTime, mode, t],
  );

  useEffect(() => {
    return () => clearTimeout(updateTimer.current);
  }, []);

  useEffect(() => {
    updateText(date);
  }, [date, updateText]);

  return (
    <Tooltip text={date && (disableTime ? DateUtils.formatDate(date) : DateUtils.formatDateTime(date))}>
      {(tooltip) => (
        <span {...tooltip} className="relative">
          {text}
        </span>
      )}
    </Tooltip>
  );
};

export default TimeAgo;
