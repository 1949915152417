import { NavigateFunction } from 'react-router-dom';
import { hasPermission } from '../hooks/permissions/usePermissions';
import { Client } from '../models/Client';
import { ClientFormStatus } from '../models/ClientFormStatus';
import { FormType } from '../models/FormTypes';
import { Roles } from '../models/Role';
import routes from '../routes';
import ObjectUtils from './ObjectUtils';
import { ParseKeys } from 'i18next';
import User from '../models/User';

export type NavRoute = {
  id: string;
  translationKey: ParseKeys<'navigation'>;
  path: string | string[];
  permissions?: string[];
  requiresArchitect?: boolean;
};

export const findRouteById = (id: string, client: Client | null, tenantId: string | null): NavRoute => {
  const route = ObjectUtils.DeepClone(routes).find((x) => x.id === id);
  if (!route) {
    console.error(`Couldn't find route with id: ${id}`);
  }

  if (route && client) {
    if (Array.isArray(route.path)) {
      route.path = route.path[0];
    }

    route.path = route.path.replace(':clientId', client.id);
  }

  if (route && tenantId) {
    if (Array.isArray(route.path)) {
      route.path = route.path[0];
    }

    route.path = route.path.replace(':tenantId', tenantId);
  }

  return route || ({ id, translationKey: 'page.not-found', path: '/404' } as const);
};

const clientSpaceRoutes = ['client-home', 'client-dashboard', 'client-modules', 'client-assets', 'client-tasks'];
export const goToClientSpacePath = (client: Client | null, currentUser: User | null): string => {
  for (const routeName of clientSpaceRoutes) {
    const route = findRouteById(routeName, client, null);
    if (route.permissions?.length) {
      if (hasPermission(client?.id, currentUser, route.permissions)) {
        return Array.isArray(route.path) ? route.path[0] : route.path;
      }
    } else {
      return Array.isArray(route.path) ? route.path[0] : route.path;
    }
  }
  throw new Error("Couldn't find route for user");
};

export const goToClientSpace = (client: Client | null, currentUser: User | null, navigate: NavigateFunction): void => {
  navigate(goToClientSpacePath(client, currentUser));
};

export const clientFormRoute = (
  clientForm: {
    id: string;
    clientId: string;
    status?: ClientFormStatus;
    type?: (typeof FormType)[keyof typeof FormType];
    templateId?: string;
  },
  currentUser: User | null,
  forcePreview?: boolean,
): string => {
  if (clientForm.type && clientForm.templateId && clientForm.type === FormType.Resource) {
    return `/clients/${clientForm.clientId}/resources/${clientForm.templateId}/${clientForm.id}`;
  }
  if (!hasPermission(clientForm.clientId, currentUser, Roles.Management) && hasPermission(clientForm.clientId, currentUser, Roles.ExternalAuditor)) {
    return `/clients/${clientForm.clientId}/forms/${clientForm.id}` + (forcePreview ? '/preview' : '');
  }

  if (clientForm.status === null || clientForm.status === undefined) {
    return `/clients/${clientForm.clientId}/forms/${clientForm.id}` + (forcePreview ? '/preview' : '');
  }

  switch (clientForm.status) {
    default:
    case ClientFormStatus.NotStarted:
    case ClientFormStatus.InProgress:
      return `/clients/${clientForm.clientId}/forms/${clientForm.id}` + (forcePreview ? '/preview' : '');
    case ClientFormStatus.SubmittedForApproval:
    case ClientFormStatus.SubmittedForValidation:
    case ClientFormStatus.Completed:
      return `/clients/${clientForm.clientId}/forms/${clientForm.id}/preview`;
  }
};

export const goToClientForm = (
  clientForm: { id: string; clientId: string; status?: ClientFormStatus; type?: (typeof FormType)[keyof typeof FormType]; templateId?: string },
  currentUser: User | null,
  navigate: NavigateFunction,
  forcePreview?: boolean,
): void => {
  navigate(clientFormRoute(clientForm, currentUser, forcePreview));
};
