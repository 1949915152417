import { lazy } from 'react';
import { Roles } from '../models/Role';
import RouteWithTitle from '../models/RouteWithTitle';

const workspaceRoutes: RouteWithTitle[] = [
  {
    id: 'client-dashboard',
    path: '/clients/:clientId/dashboard',
    translationKey: 'page.client-dashboard',
    permissions: [Roles.Management],
    component: lazy(() => import('../pages/clients/Dashboard')),
  },
  {
    id: 'client-home',
    breadcrumb: 'Home',
    path: '/clients/:clientId/home',
    translationKey: 'page.client-home',
    component: lazy(() => import('../pages/home/HomePage')),
  },
  {
    id: 'client-modules',
    breadcrumb: 'Modules',
    path: '/clients/:clientId/modules',
    translationKey: 'page.client-modules',
    component: lazy(() => import('../pages/module/Modules')),
    permissions: [Roles.Employee, Roles.ExternalContributor, Roles.ExternalAuditor],
  },
  {
    id: 'module-details',
    path: '/clients/:clientId/modules/:moduleId',
    translationKey: 'page.module-details',
    component: lazy(() => import('../pages/module/ModuleV2')),
    permissions: [Roles.Employee, Roles.ExternalContributor, Roles.ExternalAuditor],
  },
  {
    id: 'client-assets',
    path: '/clients/:clientId/assets',
    translationKey: 'page.client-assets',
    component: lazy(() => import('../pages/assets/Assets')),
    permissions: [Roles.Employee, Roles.ExternalContributor, Roles.ExternalAuditor],
  },
  {
    id: 'form-page',
    path: '/clients/:clientId/forms/:formId',
    translationKey: 'page.form-page',
    component: lazy(() => import('../pages/form/FormPage')),
    // NOTE: ExternalAuditor isn't allowed to view the form, but we only decide
    // to redirect to preview ON this page, so allowing through here
    permissions: [Roles.Employee, Roles.ExternalContributor, Roles.ExternalAuditor],
  },
  {
    id: 'form-preview',
    path: '/clients/:clientId/forms/:formId/preview',
    translationKey: 'page.form-preview',
    component: lazy(() => import('../pages/form/FormPreview')),
    permissions: [Roles.Employee, Roles.ExternalAuditor, Roles.ExternalContributor],
  },
  {
    id: 'risk-overview',
    path: '/clients/:clientId/risk',
    translationKey: 'page.risk-overview',
    component: lazy(() => import('../pages/risk/Overview')),
  },
  {
    id: 'my-org',
    path: '/clients/:clientId/organisation',
    translationKey: 'page.my-org',
    component: lazy(() => import('../pages/my-org/MyOrg')),
    permissions: [Roles.TeamLead, Roles.TeamMember],
  },
  {
    id: 'org-edit-contact',
    path: '/clients/:clientId/contacts/:contactId',
    translationKey: 'page.org-contact-edit',
    component: lazy(() => import('../pages/my-org/ContactEditPage')),
    permissions: [Roles.TeamLead, Roles.TeamMember],
  },
  {
    id: 'client-form-builder',
    path: ['/clients/:clientId/form-builder/:templateFormId/:templateFormVersion', '/clients/:clientId/form-builder'],
    translationKey: 'page.form-builder',
    permissions: [Roles.TeamLead],
    component: lazy(() => import('../pages/admin/FormBuilder')),
  },
  {
    id: 'client-module-builder',
    path: ['/clients/:clientId/module-builder/:templateModuleId/sections', '/clients/:clientId/module-builder/:templateModuleId/forms/:sectionId'],
    translationKey: 'page.module-builder',
    permissions: [Roles.TeamLead],
    component: lazy(() => import('../pages/admin/ModuleBuilder')),
  },
  {
    id: 'client-resource-builder',
    path: ['/clients/:clientId/resource-builder/:templateFormId/:templateFormVersion', '/clients/:clientId/resource-builder'],
    translationKey: 'page.resource-builder',
    permissions: [Roles.TeamLead],
    component: lazy(() => import('../pages/admin/ResourceBuilder')),
  },
  {
    id: 'client-picklist-builder',
    path: '/clients/:clientId/picklists/:picklistId',
    translationKey: 'page.picklist-details',
    permissions: [Roles.TeamLead],
    component: lazy(() => import('../pages/admin/picklist-builder/PicklistDetails')),
  },
  {
    id: 'notifications',
    path: ['/clients/:clientId/notifications', '/notifications'],
    translationKey: 'page.notifications',
    component: lazy(() => import('../pages/Notifications')),
  },
  {
    id: 'user-settings',
    path: ['/clients/:clientId/user-settings', '/user-settings'],
    translationKey: 'page.user-settings',
    component: lazy(() => import('../pages/user/Settings')),
  },
  {
    id: 'resources',
    breadcrumb: 'Resources',
    path: '/clients/:clientId/resources',
    translationKey: 'page.client-resources',
    component: lazy(() => import('../pages/resources/Resources')),
    permissions: [Roles.Employee],
  },
  {
    id: 'resource',
    path: '/clients/:clientId/resources/:resourceId',
    translationKey: 'page.client-resources',
    component: lazy(() => import('../pages/resources/Resource')),
    permissions: [Roles.Employee],
  },
  {
    id: 'resource-edit',
    path: '/clients/:clientId/resources/:resourceId/:recordId',
    translationKey: 'page.record-edit',
    component: lazy(() => import('../pages/resources/RecordEditPage')),
    permissions: [Roles.Employee],
  },
  {
    id: 'documents',
    breadcrumb: 'Document Space',
    path: '/clients/:clientId/documents',
    translationKey: 'page.documents',
    component: lazy(() => import('../pages/documents/Documents')),
  },
];

export default workspaceRoutes;
