import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import { ErrorStateProps } from '../../recoil/atoms/Errors';
import errorStateSelector from '../../recoil/selectors/ErrorStateSelector';
import IntercomUtils from '../../utils/IntercomUtils';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';

const ErrorPage: FC<ErrorStateProps> = ({ ...props }): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation('common');
  const currentClient = useRecoilValue(currentClientAtom);
  const setError = useSetRecoilState(errorStateSelector);

  useEffect(() => {
    if (props.pageUrl !== location.pathname) {
      setError(null);
    }
  }, [location.pathname, props.pageUrl, setError]);

  const clientId = currentClient?.id;

  const goBackText = clientId ? t('errors.actions.go-back') : t('errors.actions.go-home');

  const { type, goBackCallback, showGoBack, showContactSupport, showRefresh } = {
    ...props,
  };

  const contactSupport = useCallback(() => {
    if (import.meta.env.VITE_INTERCOM_APP_ID) {
      IntercomUtils.showNewMessage();
    } else {
      window.open('mailto:support@playbk.io', '_blank');
    }
  }, []);

  if (type === null) {
    return <div>error</div>;
  }

  const buttonClasses = 'whitespace-nowrap flex flex-1 justify-center items-center';

  return (
    <div className="flex min-h-screen w-full flex-col items-center px-8 pt-44">
      <div className="text-dpm-48 text-color-3 mb-2 text-center font-medium">{t(`errors.${type}.title`)}</div>
      <div className="text-dpm-32 text-color-2 mb-5 text-center font-medium">{t(`errors.${type}.subtitle`)}</div>
      <div className="text-dpm-20 text-color-3 mb-20 text-center">{t(`errors.${type}.description`)}</div>
      <div className="flex space-x-4">
        {showGoBack && (
          <Button
            className={buttonClasses}
            size={ButtonSize.M}
            type={ButtonType.PRIMARY}
            onClick={() => {
              history.go(-1);
              if (goBackCallback) goBackCallback();
            }}
          >
            {goBackText}
          </Button>
        )}
        {showRefresh && (
          <Button className={buttonClasses} size={ButtonSize.M} type={ButtonType.PRIMARY} onClick={() => history.go(0)}>
            {t('errors.actions.refresh')}
          </Button>
        )}
        {showContactSupport && (
          <Button
            className={buttonClasses}
            size={ButtonSize.M}
            type={showContactSupport || showRefresh ? ButtonType.SECONDARY : ButtonType.PRIMARY}
            onClick={contactSupport}
          >
            {t('errors.actions.contact-support')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
