import playbookImages from './images/playbook';
import { ThemeStateProps } from '../recoil/atoms/Theme';

const PLAYBOOK_THEME: ThemeStateProps = {
  name: 'playbook',
  hasSubHeading: true,
  styles: {
    color: {
      primary: {
        1: '62, 34, 52',
        2: '198, 169, 125',
      },
      accent: {
        1: '52, 125, 152',
        3: '97, 118, 142',
        'light-mid': '246, 239, 230',
        'light-5': '252, 250, 248',
      },
      semantic: {
        1: '0, 157, 101',
        2: '232, 53, 42',
        3: '199, 78, 10',
        4: '248,193,0',
        5: '0, 115, 230',
        alert: '221, 51, 51',
      },
      gray: {
        1: '66, 66, 66',
        2: '90, 87, 102',
        3: '208, 208, 208',
        4: '208, 208, 208',
        5: '232, 232, 232',
        6: '236, 236, 236',
      },
      'light-gray': {
        1: '150, 147, 157',
      },
      text: {
        1: '62, 34, 52',
        2: '90, 87, 102',
        3: '90, 87, 102',
        4: '232, 53, 42',
        'on-first-primary': {
          1: '150, 147, 157',
          2: '255,255,255',
          3: '149, 134, 144',
          4: '198, 169, 125',
        },
      },
      background: {
        1: '246, 245, 243',
        2: '255,255,255',
        sidebar: '62, 34, 52',
      },
      'progress-bar': {
        1: '55, 133, 161',
        2: '198, 169, 125',
        3: '10, 46, 90',
      },
      dashboard: {
        1: '55, 133, 161',
        2: '10, 46, 90',
        3: '214, 114, 82',
        4: '90, 87, 102',
      },
      button: {
        primary: {
          default: '52, 125, 152',
          pressed: '105, 164, 185',
          disabled: '185, 213, 222',
        },
        secondary: {
          default: '62, 34, 52',
          pressed: '90, 87, 102',
          disabled: '121, 118, 131',
        },
        toggle: {
          selected: '62, 34, 52',
          deselected: '242, 242, 242',
          disabled: '149, 134, 144',
        },
      },
      'status-tag': {
        1: '221, 51, 51',
        2: '62, 34, 52',
        3: '214, 114, 82',
        4: '55, 133, 161',
        5: '198, 169, 125',
        6: '10, 46, 90',
      },
      link: {
        1: '62, 34, 52',
      },
    },
    font: {
      sans: '"ScandiaWeb", sans-serif',
      signature: 'Oooh Baby',
    },
  },
  assets: {
    image: playbookImages,
    fonts: {
      'app-font': '/fonts/scandia/font-styles.css',
      'signature-font': '/fonts/OoohBaby/font-styles.css',
    },
  },
};

const themes: Record<string, ThemeStateProps> = {
  [PLAYBOOK_THEME.name]: PLAYBOOK_THEME,
};

export default themes;
