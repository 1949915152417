import { ApiResponse } from '../models/ApiResponse';
import { Client } from '../models/Client';
import { ClientUpdateDto } from '../models/Client';
import { PeopleFilter, PeopleResponse } from '../models/Distribution';
import { FavouriteForm } from '../models/Form';
import User from '../models/User';
import UserInvitation, { UserInviteResponse } from '../models/UserInvitation';
import BaseService from './BaseService';

class ClientService extends BaseService {
  public static async getClient(clientId: string): Promise<Client> {
    const res = await this.get<Client>(`/v1/clients/${clientId}`);
    return res.data;
  }

  public static async getClientQuickLinks(): Promise<ApiResponse<FavouriteForm[]>> {
    return await this.get(`/v1/clients/quick-links`);
  }

  public static async setClientQuickLinks(quickLinks: FavouriteForm[]): Promise<ApiResponse<boolean>> {
    return await this.post(`/v1/clients/quick-links`, quickLinks);
  }

  public static async updateClient(clientId: string, client: ClientUpdateDto): Promise<Client> {
    const res = await this.put<Client>(`/v1/clients/${clientId}`, client);
    return res.data;
  }

  public static async deleteClient(clientId: string): Promise<void> {
    await this.delete(`/v1/clients/${clientId}`);
  }

  public static async createClient(client: ClientUpdateDto): Promise<ApiResponse<Client>> {
    return this.post('/v1/clients', client);
  }

  public static getMyClients(): Promise<ApiResponse<Client[]>> {
    return this.get('/v1/clients');
  }

  public static async getUsers(includeDisabledUsers = false): Promise<User[]> {
    const res = await this.get<User[]>(`/v1/clients/users`, { params: { includeDisabledUsers } });
    return res.data;
  }

  public static inviteUser(clientId: string, invite: UserInvitation): Promise<ApiResponse<UserInviteResponse>> {
    return this.post(`/v1/clients/users`, invite);
  }

  public static removeUser(clientId: string, userId: string): Promise<ApiResponse<boolean>> {
    return this.delete(`/v1/clients/users`, { params: { userId } });
  }

  public static getAffiliates(clientId: string): Promise<ApiResponse<Client[]>> {
    return this.get(`/v1/clients/affiliates`, { params: { clientId } });
  }

  public static getPeople(filter?: PeopleFilter): Promise<ApiResponse<PeopleResponse[]>> {
    return this.get('/v1/clients/people', { params: filter });
  }
}

export default ClientService;
