import { FeatureFlagResponse, flags } from '../features/FeatureFlag';
import { ApiResponse } from '../models/ApiResponse';
import BaseService from './BaseService';

class FeatureFlagService extends BaseService {
  public static getFeatureFlagStatusses(): Promise<ApiResponse<FeatureFlagResponse>> {
    return this.post(
      '/v1/feature-flags/check',
      flags.map((str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }),
    );
  }
}

export default FeatureFlagService;
