import { FC, useEffect, createElement, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import ErrorPage from './components/error/ErrorPage';
import PageLoader from './components/shared/page-loader/PageLoader';
import usePermissions from './hooks/permissions/usePermissions';
import ErrorType from './models/Error';
import RouteWithTitle from './models/RouteWithTitle';
import { currentUserAtom } from './recoil/atoms/Auth';
import { currentClientAtom, currentTenantIdAtom } from './recoil/atoms/Clients';
import { useCurrentRoute } from './hooks/useCurrentRoute';
import AuthService from './services/AuthService';
import ClientService from './services/ClientService';
import { decodeJWT, extractFromJWT } from './utils/JwtUtils';
import { usePbkAuth } from './contexts/PbkAuthContext';
import BaseService from './services/BaseService';
import { AxiosInstance } from 'axios';
import { EventSystem } from './events/EventSystem';
import { errorAtom } from './recoil/atoms/Errors';
import { gqlState } from './indexWrapped';

const { FORBIDDEN } = ErrorType;

const EMPTY_PROMISE = Promise.resolve();

const ProtectedRouteWithTitle: FC<RouteWithTitle> = (props) => {
  const { t } = useTranslation('navigation');
  const title = props.translationKey ? t(props.translationKey) : '';
  const currentUser = useRecoilValue(currentUserAtom);
  const location = useLocation();
  const hasPermission = usePermissions();
  const [currentTenantId, setCurrentTenantId] = useRecoilState(currentTenantIdAtom);
  const { match: routeMatch } = useCurrentRoute();
  const auth = usePbkAuth();
  const currentError = useRecoilValue(errorAtom);

  useEffect(() => {
    document.title = `Playbook${title ? ' - ' + title : ''}`;
  }, [title]);

  const [switchingAccount, setSwitchingAccount] = useState(true);
  const [currentClient, setCurrentClient] = useRecoilState(currentClientAtom);
  const [httpClientHasClient, setHttpClientHasClient] = useState(!!BaseService.httpClient.defaults.headers['x-client-id']);

  useEffect(() => {
    const handler = (client: AxiosInstance) => {
      setHttpClientHasClient(!!client.defaults.headers['x-client-id']);
    };

    EventSystem.listen('http-client-recreated', handler);
    return () => EventSystem.stopListening('http-client-recreated', handler);
  }, []);

  useEffect(() => {
    const switchAccount = async () => {
      setSwitchingAccount(true);

      if (!auth.isAuthenticated) {
        setSwitchingAccount(false);
        return;
      }

      const routeClientId = routeMatch?.params.clientId;
      const outsideClientSpace = !routeClientId || routeClientId === 'undefined'; // "undefined" in the url is a string!

      const routeTenantId = routeMatch?.params.tenantId;
      const outsideTenantSpace = !routeTenantId || routeTenantId === 'undefined'; // "undefined" in the url is a string!

      if (outsideClientSpace && outsideTenantSpace) {
        setSwitchingAccount(false);
        return;
      }

      gqlState.clientId = routeClientId as string;

      if (currentClient?.id !== routeClientId && !outsideClientSpace) {
        const clientRes = await ClientService.getClient(routeClientId);
        await (auth.features.switchingAccounts ? AuthService.switchAccounts(routeClientId) : EMPTY_PROMISE);
        const currentTenantId = clientRes.tenantId;
        gqlState.tenantId = currentTenantId as string;
        setCurrentTenantId(currentTenantId);
        setCurrentClient(clientRes);
        BaseService.setClientTenantDetails(clientRes.id, clientRes.tenantId);
      } else if ((currentTenantId !== routeTenantId || outsideClientSpace) && !outsideTenantSpace) {
        setCurrentClient(null);
        await (auth.features.switchingAccounts ? AuthService.switchAccounts(routeTenantId) : EMPTY_PROMISE);
        setCurrentTenantId(routeTenantId);
        BaseService.setClientTenantDetails('', routeTenantId);
      }
      setSwitchingAccount(false);
    };

    switchAccount();
  }, [
    routeMatch?.params.clientId,
    routeMatch?.params.tenantId,
    currentClient?.id,
    currentTenantId,
    auth.features.switchingAccounts,
    auth.isAuthenticated,
    setCurrentTenantId,
    setCurrentClient,
  ]);

  const isAuthRoute = useMemo(() => location.pathname.startsWith('/auth'), [location.pathname]);

  useEffect(() => {
    const switchToDefaultAccount = async () => {
      setSwitchingAccount(true);

      if (!auth.isAuthenticated) {
        setSwitchingAccount(false);
        return;
      }

      const routeClientId = routeMatch?.params.clientId;
      const outsideClientSpace = !routeClientId || routeClientId === 'undefined'; // "undefined" in the url is a string!

      const routeTenantId = routeMatch?.params.tenantId;
      const outsideTenantSpace = !routeTenantId || routeTenantId === 'undefined'; // "undefined" in the url is a string!

      if (outsideClientSpace && outsideTenantSpace && !isAuthRoute) {
        if (auth.features.switchingAccounts) {
          const res = await AuthService.switchAccounts();
          const jwt = decodeJWT(res.data.token);
          const currentTenantId = extractFromJWT(jwt, 'tenant_id') as string;
          setCurrentTenantId(currentTenantId);
          setCurrentClient(null);
          BaseService.setClientTenantDetails('', currentTenantId);
        } else {
          const defaultIdFn = auth._implementationData.defaultAccountId;
          if (!defaultIdFn) throw new Error('No default account id provider found');

          setCurrentTenantId(defaultIdFn());
          BaseService.setClientTenantDetails('', defaultIdFn());
          setCurrentClient(null);
        }
      }
      setSwitchingAccount(false);
    };

    switchToDefaultAccount();
  }, [
    routeMatch?.params.clientId,
    isAuthRoute,
    routeMatch?.params.tenantId,
    auth.features.switchingAccounts,
    auth.isAuthenticated,
    auth._implementationData.defaultAccountId,
    setCurrentTenantId,
    setCurrentClient,
  ]);

  if (!auth.isAuthenticated) {
    if (!auth.isPending) auth.requireSignIn();
    return <PageLoader loading />;
  }

  if (!currentUser && currentError === null) {
    return <PageLoader loading />;
  }

  if (switchingAccount) {
    return <PageLoader loading />;
  }

  if (auth.features.clientHeader && routeMatch?.params.clientId && !httpClientHasClient) {
    return <PageLoader loading />;
  }

  if ((props.permissions?.length && !hasPermission(props.permissions)) || (props.requiresArchitect && !currentUser?.isArchitect)) {
    return <ErrorPage type={FORBIDDEN} showGoBack showContactSupport />;
  }

  return createElement(props.component);
};

export default ProtectedRouteWithTitle;
