import { atom } from 'recoil';
import { Layouts } from '../../layouts/Layout';
import RouteWithTitle from '../../models/RouteWithTitle';

export const layoutMarginsAtom = atom({
  key: 'layoutMargins',
  default: '',
});

export const currentLayoutAtom = atom<keyof typeof Layouts | null>({
  key: 'currentLayout',
  default: null,
});

export const currentRouteAtom = atom<RouteWithTitle | null>({
  key: 'currentRoute',
  default: null,
});
