import { Namespace, TFunction } from 'i18next';
import { Option } from '../components/Option';

export const FormType = {
  // Represents a normal form.
  Default: 0 as const,
  // Represents a normal form that should be instanciated when the module is created.
  Startup: 1 as const,
  // A form that requires validation and approval.
  Asset: 2 as const,
  // A form that is part of another form.
  SubForm: 3 as const,
  // A form that is part of another form and requires validation and approval.
  SubFormWithApproval: 4 as const,
  // A resource type
  Resource: 5 as const,
  // A document type
  Document: 6 as const,
} as const;

export const FormTypeKeys = {
  [FormType.Default]: 'common:form-type.default',
  [FormType.Startup]: 'common:form-type.startup',
  [FormType.Asset]: 'common:form-type.asset',
  [FormType.SubForm]: 'common:form-type.subform',
  [FormType.SubFormWithApproval]: 'common:form-type.subform-approval',
  [FormType.Resource]: 'common:form-type.resource',
  [FormType.Document]: 'common:form-type.document',
} as const;

export const formTypeOptions = (t: TFunction<Namespace> | undefined = undefined): Option<string, string | number>[] => {
  return [
    {
      id: FormType.Default.toString(),
      text: t ? t(FormTypeKeys[FormType.Default]) : 'Task',
      value: FormType.Default,
    },
    {
      id: FormType.Startup.toString(),
      text: t ? t(FormTypeKeys[FormType.Startup]) : 'Start-up Task',
      value: FormType.Startup,
    },
    {
      id: FormType.Asset.toString(),
      text: t ? t(FormTypeKeys[FormType.Asset]) : 'Asset',
      value: FormType.Asset,
    },
    {
      id: FormType.SubForm.toString(),
      text: t ? t(FormTypeKeys[FormType.SubForm]) : 'Subform',
      value: FormType.SubForm,
    },
    {
      id: FormType.SubFormWithApproval.toString(),
      text: t ? t(FormTypeKeys[FormType.SubFormWithApproval]) : 'Subform + Approval',
      value: FormType.SubFormWithApproval,
    },
    {
      id: FormType.Resource.toString(),
      text: t ? t(FormTypeKeys[FormType.Resource]) : 'Resource',
      value: FormType.Resource,
    },
    {
      id: FormType.Document.toString(),
      text: t ? t(FormTypeKeys[FormType.Document]) : 'Document',
      value: FormType.Document,
    },
  ];
};
