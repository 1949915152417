type Token = string | undefined | null;
/* eslint-disable @typescript-eslint/no-explicit-any */
type TokenValue = Record<string, any> | null;

export const decodeJWT = (token: Token): TokenValue => {
  const split = token?.split('.') || [];
  if (split.length !== 3) {
    return null;
  }

  try {
    return JSON.parse(atob(split[1]));
  } catch {
    return null;
  }
};

export const extractFromJWT = (token: TokenValue, key: string): string | number | string[] | null => {
  return token?.[key] || null;
};

export const extractDateFromJWT = (token: TokenValue, key: string): Date | null => {
  const value = extractFromJWT(token, key);
  return value ? new Date((value as number) * 1000) : null;
};

export const isJWTValid = (token: Token): boolean => {
  const decoded = decodeJWT(token);
  if (!decoded) {
    return false;
  }

  const expireDate = extractDateFromJWT(decoded, 'exp');
  return expireDate ? new Date() < expireDate : true;
};

export const extractRolesFromJWT = (token: Token): string[] | null => {
  const value = extractFromJWT(decodeJWT(token), 'role') as string[];
  return value || null;
};
