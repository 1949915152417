export enum DateInterval {
  DAY = 0,
  WEEK = 3,
  MONTH = 1,
  YEAR = 2,
}

export const DateIntervalKeys = {
  [DateInterval.DAY]: 'common:date.day',
  [DateInterval.WEEK]: 'common:date.week',
  [DateInterval.MONTH]: 'common:date.month',
  [DateInterval.YEAR]: 'common:date.year',
} as const;
