import { createContext, useContext } from 'react';

type TooltipContextType = {
  setBlocking: (isBlocked: boolean) => void;
};

const NOOP = () => {};

const DEFAULT: TooltipContextType = {
  setBlocking: NOOP,
};

const TooltipContext = createContext<TooltipContextType>(DEFAULT);

export const TooltipProvider = TooltipContext.Provider;
export const useTooltipContext = () => useContext(TooltipContext);
