import { lazy } from 'react';
import { Roles } from '../models/Role';
import RouteWithTitle from '../models/RouteWithTitle';

const clientRoutes: RouteWithTitle[] = [
  {
    id: 'clients-list',
    path: '/clients',
    translationKey: 'page.clients-list',
    component: lazy(() => import('../pages/clients/Clients')),
  },
  {
    id: 'new-client',
    path: '/clients/new',
    translationKey: 'page.new-client',
    permissions: [Roles.ConsultantManager],
    component: lazy(() => import('../pages/clients/AddClient')),
  },
  {
    id: 'consultant-dashboard',
    path: '/clients/:clientId',
    translationKey: 'page.client',
    permissions: [Roles.Consultant, Roles.ExternalConsultant],
    component: lazy(() => import('../pages/clients/ClientDetails')),
  },
];

export default clientRoutes;
