import { useEffect, useMemo, useRef, useState } from 'react';
import {
  FloatingArrow,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useTooltipContext } from '../contexts/TooltipContext';

export const useTooltip = (truncatedTextMode?: boolean, ariaRole: 'tooltip' | 'label' = 'tooltip') => {
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isBlockedByChild, setIsBlockedByChild] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const { setBlocking: setParentBlocking } = useTooltipContext();

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    strategy: 'fixed',
    placement: 'bottom',
    middleware: [
      offset(10),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
      size({
        padding: 10,
        apply({ availableWidth, elements }) {
          const maxWidth = Math.min(availableWidth, window.innerWidth / 3);
          elements.floating.style.maxWidth = `${maxWidth}px`;
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    role: ariaRole,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  const arrowEl = useMemo(() => {
    return <FloatingArrow ref={arrowRef} context={context} className="fill-primary-1" />;
  }, [context]);

  useEffect(() => {
    // Children-popups can block parent-popups (no two tooltips active at the same time)
    setParentBlocking(isOpen);
  }, [isOpen, setParentBlocking]);

  useEffect(() => {
    if (!truncatedTextMode) return;

    const anchor = refs.reference.current as HTMLElement;
    if (!anchor) return;

    const truncatedEls = Array.from(anchor.querySelectorAll('.truncate, [class*="line-clamp-"]'));
    if (anchor.classList.contains('truncate') || anchor.classList.contains('line-clamp-')) {
      truncatedEls.push(anchor);
    }

    const anyTruncated = truncatedEls.some((el) => el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight);
    setIsTruncated(anyTruncated);
  }, [isOpen, refs.reference, truncatedTextMode]);

  return {
    anchorProps: { ref: refs.setReference, tabIndex: 0, ...getReferenceProps() },
    floatingProps: {
      ref: refs.setFloating,
      ...getFloatingProps({
        style: {
          wordBreak: 'break-word',
          hyphens: 'auto',
          zIndex: 9999,
          ...floatingStyles,
        },
      }),
    },
    arrowEl,
    anchorRef: refs.reference,
    floatingRef: refs.floating,
    isActive: isOpen && !isBlockedByChild && (truncatedTextMode ? isTruncated : true),
    setIsBlockedByChild,
  };
};
