import { atom } from 'recoil';

const inConsultantSpaceAtom = atom<boolean>({
  key: 'inConsultantDashboardState',
  default: false,
});

const topNavHeadingAtom = atom<string | null>({
  key: 'topNavHeading',
  default: null,
});

const inAdminSpaceAtom = atom<boolean>({
  key: 'inAdminSpaceState',
  default: false,
});

export { inConsultantSpaceAtom, topNavHeadingAtom, inAdminSpaceAtom };
