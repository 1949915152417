type Role = {
  code: (typeof Roles)[keyof typeof Roles];
  serviceCode: string;
  name: string;
  scopes: string[];
  accountId: string;
  active: boolean;
  createdAt: string;
};

export type UserRole = {
  id: string;
  userId: string;
  accountId: string;
  serviceCode: string;
  roleCode: Roles;
  active: boolean;
  createdAt: string;
};

// Role enum to match up with the backend
export enum Roles {
  ExternalAuditor = 'EXTERNAL_AUDITOR',
  ExternalContributor = 'EXTERNAL_CONTRIBUTOR',
  Employee = 'EMPLOYEE',
  Management = 'MANAGEMENT',
  TeamMember = 'TEAM_MEMBER',
  TeamLead = 'TEAM_LEAD',
  ExternalConsultant = 'EXTERNAL_CONSULTANT',
  Consultant = 'CONSULTANT',
  ConsultantManager = 'CONSULTANT_MANAGER',
  SuperAdmin = 'SUPER_ADMIN',
}

// Higher number = higher position in hierarchy. Numbers themselves don't mean anything. KEEP IN SYNC with BE
export const RolesHierarchy = {
  [Roles.ExternalConsultant]: 999, // legacy role

  [Roles.SuperAdmin]: 8,
  [Roles.ConsultantManager]: 7,
  [Roles.Consultant]: 6,
  [Roles.TeamLead]: 5,
  [Roles.TeamMember]: 4,
  [Roles.Management]: 3,
  [Roles.Employee]: 2,
  [Roles.ExternalContributor]: 2,
  [Roles.ExternalAuditor]: 1,
} as const;

export const getContainingRoles = (role: Roles): Roles[] => {
  const level = RolesHierarchy[role];

  // Not doing <= because roles on the same level would be included then
  return (
    Object.entries(RolesHierarchy)
      .filter(([_, rank]) => rank < level)
      .map(([role, _]) => role) as Roles[]
  ).concat(role);
};

export const getHighestRole = (roles: string[]): keyof typeof RolesHierarchy => {
  return roles
    .map((role) => ({ role: role as keyof typeof RolesHierarchy, level: RolesHierarchy[role as keyof typeof RolesHierarchy] }))
    .filter((x) => x.level < 999)
    .sort((a, b) => b.level - a.level)[0].role;
};

export default Role;
