import { useMemo } from 'react';
import { matchRoutes, RouteObject, useLocation } from 'react-router-dom';
import RouteWithTitle from '../models/RouteWithTitle';
import Routes from '../routes';

// Memoize the flattened routes outside the hook
const flattenRoutes = (routes: RouteWithTitle[]): RouteObject[] => {
  const flatRoutes: RouteObject[] = [];
  for (const route of routes) {
    if (Array.isArray(route.path)) {
      for (let index = 0; index < route.path.length; index++) {
        const element = route.path[index];
        flatRoutes.push({ ...route, path: element });
      }
    } else {
      flatRoutes.push({ ...route, path: route.path as string });
    }
  }
  return flatRoutes;
};

const memoizedFlatRoutes = flattenRoutes(Routes);

export const useCurrentRoute = () => {
  const location = useLocation();

  const routeMatch = useMemo(() => {
    const matches = matchRoutes(memoizedFlatRoutes, location);
    return matches?.length ? matches[0] : null;
  }, [location]);

  const routeId = useMemo(() => (routeMatch?.route as RouteWithTitle)?.id, [routeMatch]);

  return { match: routeMatch, id: routeId, route: routeMatch?.route as RouteWithTitle | null };
};
