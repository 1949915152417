import { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import StatusTag, { StatusVariant } from '../components/shared/tags/StatusTag';
import DateUtils from '../utils/DateUtils';
import { FormType } from './FormTypes';
import TimeAgo from '../components/shared/TimeAgo';
import { PeriodicReviewStatus } from './PeriodicReview';
import useStatusText from '../hooks/useStatusText';

export enum ClientFormStatus {
  NotStarted = 0,
  InProgress = 1,
  SubmittedForValidation = 2,
  SubmittedForApproval = 3,
  Completed = 4,
}

export const formStatusKeys = {
  [ClientFormStatus.NotStarted]: 'common:form-status.not-started',
  [ClientFormStatus.InProgress]: 'common:form-status.in-progress',
  [ClientFormStatus.SubmittedForValidation]: 'common:form-status.submitted-for-validation',
  [ClientFormStatus.SubmittedForApproval]: 'common:form-status.submitted-for-approval',
  [ClientFormStatus.Completed]: 'common:form-status.completed',
} as const;

export const documentStatusKeys = {
  [ClientFormStatus.NotStarted]: 'common:document-status.not-started',
  [ClientFormStatus.InProgress]: 'common:document-status.in-progress',
  [ClientFormStatus.SubmittedForValidation]: 'common:document-status.submitted-for-validation',
  [ClientFormStatus.SubmittedForApproval]: 'common:document-status.submitted-for-approval',
  [ClientFormStatus.Completed]: 'common:document-status.completed',
} as const;

export const clientFormStatusVariants = {
  [ClientFormStatus.NotStarted]: StatusVariant.GRAY,
  [ClientFormStatus.InProgress]: StatusVariant.BLUE,
  [ClientFormStatus.SubmittedForValidation]: StatusVariant.BLUE,
  [ClientFormStatus.SubmittedForApproval]: StatusVariant.BLUE,
  [ClientFormStatus.Completed]: StatusVariant.GREEN,
};

export type ActivityType = {
  status: ClientFormStatus;
  dueDateUtc?: string | null;
  type: (typeof FormType)[keyof typeof FormType];
  archivedUtc?: string | null;
  nextReviewStartDateUtc?: string;
  periodicReviewDueDateUtc?: string;
  periodicReviewStatus?: PeriodicReviewStatus;
  lastReviewCompletedDateUtc?: string;
  periodicReviewCreatedDateUtc?: string;
} & (
  | {
      requiresApproval: boolean;
    }
  | {
      form: {
        requiresApproval?: boolean;
      };
    }
);

export const FormStatusTag: FC<{ activity: ActivityType; preserveSpace?: boolean; align?: 'center' | 'left' }> = ({
  activity,
  preserveSpace = true,
  align = 'center',
}) => {
  const { t } = useTranslation(['common', 'module']);

  const [periodicSubText, periodicHelpText] = useMemo(() => {
    const hasPeriodicReview = activity.periodicReviewStatus !== null;

    if (!hasPeriodicReview) {
      // No messages
      return ['', ''];
    }

    switch (activity.periodicReviewStatus) {
      case PeriodicReviewStatus.ReviewOverdue:
        return [
          t('common:periodic-review-status.overdue'),
          <div key={1}>
            <Trans
              t={t}
              i18nKey="common:periodic-review-help.overdue"
              components={{
                Title: <span className="text-gray-300" />,
                TimeAgo: <TimeAgo disableTime date={new Date(activity.periodicReviewDueDateUtc as string)} />,
              }}
            />
          </div>,
        ];
      case PeriodicReviewStatus.UnderReview:
        return [
          t('common:periodic-review-status.under-review'),
          <div key={1}>
            <div>
              <Trans
                t={t}
                i18nKey="common:periodic-review-help.instantiated"
                components={{
                  Title: <span className="text-gray-300" />,
                  TimeAgo: <TimeAgo disableTime date={new Date(activity.periodicReviewCreatedDateUtc as string)} />,
                }}
              />
            </div>
            <div>
              {activity.periodicReviewDueDateUtc && (
                <Trans
                  t={t}
                  i18nKey="common:periodic-review-help.dueBy"
                  values={{ Date: DateUtils.formatDate(new Date(activity.periodicReviewDueDateUtc)) }}
                  components={{
                    Title: <span className="text-gray-300" />,
                  }}
                />
              )}
            </div>
          </div>,
        ];
      case PeriodicReviewStatus.UpcomingReview:
        return [
          t('common:periodic-review-status.upcoming'),
          <div key={1}>
            <div>
              <Trans
                t={t}
                i18nKey="common:periodic-review-help.starting-in"
                components={{
                  Title: <span className="text-gray-300" />,
                  TimeTill: <TimeAgo disableTime mode="till" date={new Date(activity?.nextReviewStartDateUtc as string)} />,
                }}
              />
            </div>
            {activity.lastReviewCompletedDateUtc && (
              <div>
                <Trans
                  t={t}
                  i18nKey="common:periodic-review-help.previous-review"
                  components={{
                    Title: <span className="text-gray-300" />,
                  }}
                  values={{
                    Date: DateUtils.formatDate(new Date(activity.lastReviewCompletedDateUtc)),
                  }}
                />
              </div>
            )}
          </div>,
        ];
      case PeriodicReviewStatus.ReviewCompleted:
        return [
          '', // no subtext
          <div key={1}>
            <div>
              <Trans
                t={t}
                i18nKey="common:periodic-review-help.previous-review"
                values={{
                  Date: DateUtils.formatDate(new Date(activity.lastReviewCompletedDateUtc as string)),
                }}
                components={{
                  Title: <span className="text-gray-300" />,
                }}
              />
            </div>
            {activity.nextReviewStartDateUtc && (
              <div>
                <Trans
                  t={t}
                  i18nKey="common:periodic-review-help.next-review"
                  values={{ Date: DateUtils.formatDate(new Date(activity.nextReviewStartDateUtc)) }}
                  components={{
                    Title: <span className="text-gray-300" />,
                  }}
                />
              </div>
            )}
          </div>,
        ];
    }

    // No messages
    return ['', ''];
  }, [
    activity.lastReviewCompletedDateUtc,
    activity?.nextReviewStartDateUtc,
    activity.periodicReviewCreatedDateUtc,
    activity.periodicReviewDueDateUtc,
    activity.periodicReviewStatus,
    t,
  ]);

  const statusText = useStatusText(activity);

  return (
    <StatusTag
      data-cy="form-status-tag"
      text={statusText}
      statusType={activity.archivedUtc ? StatusVariant.RED : clientFormStatusVariants[activity.status]}
      bottomText={activity.archivedUtc ? '' : periodicSubText}
      infoText={activity.archivedUtc ? '' : periodicHelpText}
      preserveSpace={preserveSpace}
      align={align}
    />
  );
};
